/* .random {
	color: blue;
	animation: blinking 2.5s infinite;
	font-size: 30px;
}

@keyframes blinking {
	0% {
		color: white;
	}
	47% {
		color: #000;
	}
	62% {
		color: transparent;
	}
	97% {
		color: transparent;
	}
	100% {
		color: blue;
	}
} */

.random {
	color: blue;
	animation: blink-animation 4s steps(5, start) infinite;
	-webkit-animation: blink-animation 3s steps(5, start) infinite;
}
@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
