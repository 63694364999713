.export {
	color: beige;
	background-color: green;
	margin-top: 5px;
	margin-left: 50%;
}

.ReactTable .rt-thead {
	/* background-color: rgb(128, 0, 0); */
	/* background-color: cornflowerblue; */
	font-size: 13px;
	color: white;
}

.ReactTable {
	margin-top: 20px;
	margin-bottom: 20px;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 100px;
}

.right {
	text-align: center;
	margin-top: 5px;
	margin-bottom: 20px;
	line-height: '5px';
}

.flex-container {
	display: flex;
	background-color: white;
	flex: auto;
}
.flex-container > div {
	/* background-color: blue; */
	margin: 1px;
	padding: 10px;
	font-size: 20px;
	flex-grow: 1;
	/* height: 50x; */
}
