.notice {
	/* width: 1200px; */
	margin-top: 70px;
	margin-bottom: 100px;
	/* margin-left: 100px;
    margin-right: 100px; */
	font-size: 20px;
	color: black;
}

.h1 {
	text-align: center;
}

.noticelist {
	/* text-align: center; */
}
