.container {
	margin-top: 20px;
	margin-bottom: 100px;
	/* margin-left: 50px; */
	/* margin-right: 50px; */
	/* background-color: yellow; */
}

.input-group-text {
	color: black;
	font-weight: bold;
	background-color: yellow;
	font-size: 17px;
}

.input-group {
	margin-bottom: 7px;
	color: blue;
}

.input-group-prepend {
	background-color: yellow;
	color: blue;
}
