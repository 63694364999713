h3 {
	color: green;
}
.text {
	animation: blinking 1.5s infinite;
	font-size: 30px;
}
@keyframes blinking {
	0% {
		color: red;
	}
	47% {
		color: #000;
	}
	62% {
		color: transparent;
	}
	97% {
		color: transparent;
	}
	100% {
		color: #000;
	}
}
